import { RootContext } from '@/App.context'
import { makeAutoObservable } from 'mobx'

export interface IVideoMeta {
  ID: string // 视频的id
  IMDb_ID: string // 所属电影的id
  coverUrl: string // 视频封面图 // oss 图片 url
  videoUrl: string // 视频链接 // oss MP4 url
  originalVideoUrl: string // 原始视频链接
  originalAudioUrl: string // 原始音频链接
  audioUrl: string
  movieName: string // 所属电影名
  index: number // 视频片段序号
  tags: string[] // 所属电影标签
  languages: string[] // 所属电影语言
  regions: string[]
  score: string
  release_date: string
  directors: string
  storyline: string
}

export default class VideoStore {
  public context: RootContext

  public pageNumber
  public videoList: IVideoMeta[] = []
  constructor(context: RootContext) {
    this.context = context
    this.pageNumber = localStorage.getItem('pageNumber')
      ? parseInt(localStorage.getItem('pageNumber'))
      : 1
    makeAutoObservable(this, {}, { autoBind: true })
  }

  setPageNumber = (num) => {
    this.pageNumber = num
    localStorage.setItem('pageNumber', num)
  }
  setVideoList = (data: IVideoMeta[]) => {
    this.videoList = [...data]
  }
  getVideoMetaByID = (ID: string) => {
    const result = this.videoList.find((item) => item.ID === ID)
    return result
  }
}
