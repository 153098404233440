import styles from './VideoCard.module.scss'
import { useStyles } from '@/hooks/styles'
import { FC, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { motionEasing } from '@/config'
import { Popover, Spin, Tag } from 'antd'
import { IVideoMeta } from '@/store/videoStore'
import videojs from 'video.js'

interface IVideoCard {
  videoMeta: IVideoMeta
  onClick?: () => void
}

export const VideoCard: FC<IVideoCard> = ({ videoMeta, onClick }) => {
  const styleClass = useStyles(styles)
  const videoRef = useRef(null)

  const [ifHover, setIfHover] = useState<boolean>(false)
  const [videoReady, setVideoReady] = useState<boolean>(false)
  const [test, setTest] = useState(
    Array.from({ length: 24 }, () => Math.floor(Math.random() * 7 + 5))
  )

  const handlePlay = () => {
    videoRef.current && videoRef.current.play()
  }
  const handlePause = () => {
    videoRef.current && videoRef.current.pause()
  }

  const getMovieName = (movieName: string) => {
    return movieName.replace(' - 电影', '')
  }
  const getLanguage = (languages: string[]) => {
    let result = ''
    languages &&
      languages.slice(0, 2).map((item, _index) => (result += ` ${item}`))
    return result
  }

  useEffect(() => {
    const videoPlayer = videojs(videoRef.current)
    videoPlayer.on('loadedmetadata', () => {
      setVideoReady(true)
    })
    return () => videoPlayer.dispose()
  }, [])

  return (
    <Popover content={`ID: ${videoMeta.ID} - IMDb_ID: ${videoMeta.IMDb_ID}`}>
      <motion.div
        {...styleClass(['layout'])}
        onMouseEnter={() => {
          videoReady && handlePlay()
          videoReady && setIfHover(true)
        }}
        onMouseLeave={() => {
          videoReady && handlePause()
          videoReady && setIfHover(false)
        }}
        whileHover={{ scale: 1.02 }}
        transition={{ ...motionEasing }}
        onClick={() => onClick && onClick()}
      >
        <div {...styleClass(['video-container'])}>
          <video
            ref={videoRef}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            className="video-js"
            muted
            // controls
            preload="auto"
            // poster={videoMeta.coverUrl}
          >
            <source src={videoMeta.videoUrl} type="video/mp4"></source>
          </video>
        </div>

        <motion.div
          {...styleClass(['loading-layout'])}
          animate={{ opacity: !videoReady ? 1 : 0 }}
          transition={{ ...motionEasing }}
        >
          <Spin style={{ marginTop: -28 }} />
        </motion.div>

        <div {...styleClass(['language-layout'])}>
          <div {...styleClass(['language-tag'])}>
            <div {...styleClass(['language-tag-text'])}>
              {getLanguage(videoMeta.languages)}
            </div>
          </div>
        </div>

        <div {...styleClass(['info-layout'])}>
          <div {...styleClass(['info-meta'])}>
            <div {...styleClass(['info-meta-name'])}>
              {getMovieName(videoMeta.movieName)}
            </div>
            <div
              {...styleClass(['info-meta-index'])}
            >{`#${videoMeta.index}`}</div>
            <div {...styleClass(['info-meta-tags'])}>
              {videoMeta.tags &&
                videoMeta.tags.slice(0, 6).map((tag, index) => (
                  <Tag key={`${videoMeta.ID}-${tag}`} bordered={false}>
                    {tag}
                  </Tag>
                ))}
            </div>
          </div>

          <div {...styleClass(['info-bar'])}>
            {test.map((item, index) => (
              <div
                key={`${videoMeta.ID}-${item}-${index}`}
                {...styleClass(['info-bar-chart-wrapper'])}
              >
                <motion.div
                  {...styleClass(['info-bar-chart-item'], { height: item })}
                  initial={{ opacity: 0.2 }}
                  animate={{ opacity: ifHover ? 0.8 : 0.2 }}
                  transition={{ ...motionEasing }}
                ></motion.div>
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </Popover>
  )
}
