import { FC, useEffect, useRef, useState } from 'react'
import styles from './DataDetail.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Skeleton, Button, Typography, Modal } from 'antd'
import AudioPlayer from 'react-h5-audio-player'
import videojs from 'video.js'
import WaveSurfer from 'wavesurfer.js'
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import Minimap from 'wavesurfer.js/dist/plugins/minimap.esm.js'
import { CaretLeftOutlined } from '@ant-design/icons'
import { IVideoMeta } from '@/store/videoStore'
import axios from 'axios'
import { baseUrl } from '@/config'

const DataDetail: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const navigation = useNavigate()
  const location = useLocation()
  const { ID } = useParams()

  const containerRef = useRef(null)
  const videoRef = useRef(null)

  const [ifLoading, setIfLoading] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)
  const [videoMeta, setVideoMeta] = useState<IVideoMeta>()

  let videoPlayer
  let ws
  const initWS = () => {
    ws = WaveSurfer.create({
      container: containerRef.current,
      waveColor: '#C1C6FF',
      progressColor: c('primary'),
      // Pass the video element in the `media` param
      media: videoRef.current,
      plugins: [
        TimelinePlugin.create(),
        Minimap.create({
          height: 20,
          waveColor: '#ddd',
          progressColor: '#999',
          // the Minimap takes all the same options as the WaveSurfer itself
        }),
      ],
      renderFunction: (channels, ctx) => {
        const { width, height } = ctx.canvas
        const scale = channels[0].length / width
        const step = 4

        ctx.translate(0, height / 2)
        ctx.strokeStyle = ctx.fillStyle
        ctx.beginPath()

        for (let i = 0; i < width; i += step * 2) {
          const index = Math.floor(i * scale)
          const value = Math.abs(channels[0][index])
          let x = i
          let y = value * height

          ctx.moveTo(x, 0)
          ctx.lineTo(x, y)
          ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, true)
          ctx.lineTo(x + step, 0)

          x = x + step
          y = -y
          ctx.moveTo(x, 0)
          ctx.lineTo(x, y)
          ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, false)
          ctx.lineTo(x + step, 0)
        }

        ctx.stroke()
        ctx.closePath()
      },
    })
  }

  const getMovieName = (movieName: string) => {
    return movieName.replace(' - 电影', '')
  }
  const getRegion = (regions: string[]) => {
    let result = ''
    regions.map((item, _index) => (result += `${item} `))
    return result
  }

  const getVideoMeta = async () => {
    await axios
      .post(`${baseUrl}/app/video/video/getDetail`, { ID: ID })
      .then((res) => {
        console.log(res.data.data)
        setVideoMeta(res.data.data)
        // videoStore.setVideoList(res.data.data)
        // messageApi.success({ key: 'getData', content: '数据加载完毕' })
        // setIfLoading(false)
        return
      })
      .catch((error) => {
        console.log('error', error)
        // messageApi.error({ key: 'getData', content: '数据加载失败' })
        // setIfLoading(false)
        return
      })
  }

  useEffect(() => {
    getVideoMeta()
  }, [])

  useEffect(() => {
    if (videoMeta) {
      videoPlayer = videojs(videoRef.current)

      videoPlayer.on('loadedmetadata', () => {
        console.log('video loaded')
        initWS()
        ws.on('redrawcomplete', () => {
          setIfLoading(false)
        })
      })

      videoPlayer.src(videoMeta.videoUrl)
    }

    return () => {
      if (videoMeta) {
        videoPlayer.dispose()
        ws && ws.destroy()
      }
    }
  }, [videoMeta])

  return (
    <>
      <div {...styleClass(['layout'])}>
        <div {...styleClass(['header'])}>
          <Button
            style={{ width: 32, height: 32, padding: 0 }}
            onClick={() => {
              if (location.state && location.state.jumped) {
                navigation(-1)
              } else {
                navigation('/data_browser')
              }
            }}
          >
            <CaretLeftOutlined rev={null} />
          </Button>
          <div {...styleClass(['header-text'])}>
            {videoMeta &&
              `电影:${getMovieName(videoMeta.movieName)} --- 片段序号:${
                videoMeta.index
              }`}
          </div>
        </div>
        <div {...styleClass(['content'])}>
          <div {...styleClass(['media'])}>
            <div {...styleClass(['media-video'])}>
              <video
                ref={videoRef}
                {...styleClass([], {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  boxShadow: '0 0 3px 0 rgba(255, 255, 255, 0.35)',
                  borderRadius: 6,
                  overflow: 'hidden',
                })}
                className="video-js"
                controls
                preload={'auto'}
                // poster={videoMeta.coverUrl}
              >
                <source
                  type="video/mp4"
                  src={videoMeta && videoMeta.videoUrl}
                ></source>
              </video>
            </div>

            <div {...styleClass(['media-audio-wrapper'])}>
              <div {...styleClass(['media-audio'])} ref={containerRef}></div>
              {ifLoading && (
                <Skeleton active {...styleClass(['media-audio-spin'])} />
              )}
            </div>
            <Button
              type={'default'}
              {...styleClass(['media-drawer-trigger'])}
              onClick={() => setOpen(true)}
            >
              原始音/视频
            </Button>
          </div>

          <div {...styleClass(['info'])}>
            <div {...styleClass(['info-card'])}>
              <div {...styleClass(['info-card-header'])}>
                <Typography.Text {...styleClass(['info-card-header-text'])}>
                  {videoMeta &&
                    `ID:${videoMeta.ID} --- IMDb_ID:${videoMeta.IMDb_ID}`}
                </Typography.Text>
              </div>
              <div {...styleClass(['info-card-divider'])}></div>
              <div {...styleClass(['info-card-content'])}>
                <div {...styleClass(['info-card-content-block'])}>
                  <div {...styleClass(['info-card-content-title'])}>
                    {`类型: `}
                  </div>
                  <div {...styleClass(['info-card-content-tags'])}>
                    {videoMeta &&
                      videoMeta.tags.map((item, index) => (
                        <Typography.Text
                          key={`${item}-${index}`}
                          code
                          {...styleClass(['info-card-content-tags-text'])}
                        >
                          {item}
                        </Typography.Text>
                      ))}
                  </div>
                </div>
                <div {...styleClass(['info-card-content-block'])}>
                  <div {...styleClass(['info-card-content-title'])}>
                    {`国家: `}
                  </div>
                  <div {...styleClass(['info-card-content-text'])}>
                    {videoMeta && getRegion(videoMeta.regions)}
                  </div>
                </div>
                <div {...styleClass(['info-card-content-block'])}>
                  <div {...styleClass(['info-card-content-title'])}>
                    {`评分: `}
                  </div>
                  <Typography.Text
                    mark
                    {...styleClass(['info-card-content-text'])}
                  >
                    {videoMeta && `${videoMeta.score}`}
                  </Typography.Text>
                </div>
                <div {...styleClass(['info-card-content-block'])}>
                  <div {...styleClass(['info-card-content-title'])}>
                    {`上映日期: `}
                  </div>
                  <Typography.Text
                    keyboard
                    {...styleClass(['info-card-content-text'])}
                  >
                    {videoMeta && `${videoMeta.release_date}`}
                  </Typography.Text>
                </div>
                <div {...styleClass(['info-card-content-block'])}>
                  <div {...styleClass(['info-card-content-title'])}>
                    {`导演: `}
                  </div>
                  <div {...styleClass(['info-card-content-text'])}>
                    {videoMeta && `${videoMeta.directors}`}
                  </div>
                </div>
                <div {...styleClass(['info-card-content-block'])}>
                  <div {...styleClass(['info-card-content-title'])}>
                    {`描述: `}
                  </div>
                  <div {...styleClass(['info-card-content-text'])}>
                    {videoMeta && `${videoMeta.storyline}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        width={780}
        title="原始音/视频"
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        open={open}
        footer={[
          <Button
            key={'confirm'}
            type={'primary'}
            onClick={() => setOpen(false)}
          >
            确认
          </Button>,
        ]}
      >
        <div {...styleClass(['drawer-layout'])}>
          <video
            // ref={videoRef}
            {...styleClass([], {
              width: 720,
              height: 405,
              objectFit: 'contain',
              boxShadow: '0 0 3px 0 rgba(255, 255, 255, 0.35)',
              borderRadius: 6,
              overflow: 'hidden',
            })}
            className="video-js"
            controls
            preload={'auto'}
            // poster={videoMeta.coverUrl}
          >
            <source
              type="video/mp4"
              src={videoMeta && videoMeta.originalVideoUrl}
            ></source>
          </video>
          <AudioPlayer
            style={{ width: 720 }}
            autoPlay={false} // 禁止自动播放
            hasDefaultKeyBindings={false} // 取消键盘绑定
            showFilledVolume={true}
            src={videoMeta && videoMeta.originalAudioUrl}
          />
        </div>
      </Modal>
    </>
  )
}

export default DataDetail
