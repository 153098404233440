import styles from './App.module.scss'
import './global.scss'
import './overlay.scss'
import './i18n'
import '@arco-design/web-react/dist/css/arco.css'
import 'animate.css/animate.css'
import 'video.js/dist/video-js.css'
import 'react-h5-audio-player/src/styles.scss'
import React, { useCallback, useEffect, useState } from 'react'
import { AppContext, RootContext } from '@/App.context'
import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import { ConfigProvider, theme } from 'antd'
import { useLang } from '@/hooks/i18n'
import { useForceUpdate } from '@/hooks'
import commonLang from '@/locale/common'
import { useColorVar, useStyles } from '@/hooks/styles'
import { router } from '@/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import debounce from 'lodash/debounce'
import { isDev } from '@/config'
import { ScrollerMotion } from 'scroller-motion'

const App = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const { t, i18n } = useLang(commonLang, 'common')
  const [, forceUpdate] = useForceUpdate()
  const [context] = useState<RootContext>(
    () => new RootContext({ t, i18n }, forceUpdate)
  )
  const rootStore = context.store

  const element = useRoutes(router)
  const location = useLocation()
  const navigation = useNavigate()

  const handleResizeWindowDebounce = useCallback(
    debounce(
      () => {
        // debounce
        console.log(window.innerWidth)
      },
      200,
      { leading: false, trailing: true, maxWait: 1000 }
    ),
    []
  )

  useEffect(() => {
    isDev && window.addEventListener('resize', handleResizeWindowDebounce)
    // window.addEventListener('scroll', handleScroll)

    /* const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    if (isChrome) {
      document.documentElement.classList.add('chrome-hide-scrollbar')
    } else {
      document.documentElement.classList.add('safari-show-scrollbar')
    } */

    return () => {
      window.removeEventListener('resize', handleResizeWindowDebounce)
      // window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    // 临时：默认定向到/data_browser
    if (location.pathname === '/') {
      navigation('/data_browser')
    }
  }, [])

  useEffect(() => {
    // console.log('[Router]', location.pathname, 'enter')
    NProgress.done()
    return () => {
      // console.log('[Router]', location.pathname, 'leave')
      NProgress.start()
    }
  }, [location.pathname])

  return (
    <>
      {/* Antd 全局样式 */}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#7782F7',
            colorPrimaryHover: '#8791F7',
            colorBgContainer: '#1E2329',
            colorBgLayout: '#16191D',
          },
          algorithm: theme.darkAlgorithm,
        }}
      >
        {/* app上下文 */}
        <AppContext.Provider value={context}>
          <ScrollerMotion disabled>{element}</ScrollerMotion>
        </AppContext.Provider>
      </ConfigProvider>
    </>
  )
}

export default App
