import { find, isNil } from 'lodash'

export const mockSadDataset = {
  id: 0,
  name: '悲伤数据集',
  sortKey: '悲伤情绪唤起强度',
  segments: [
    '0001_000',
    '0001_001',
    '0001_003',
    '0001_004',
    '0001_005',
    '0001_006',
    '0001_007',
    '0001_010',
    '0001_011',
    '0001_012',
    '0001_013',
    '0001_014',
    '0001_015',
    '0006_001',
    '0006_002',
    '0006_003',
    '0006_005',
    '0006_006',
    '0006_009',
    '0006_010',
    '0006_011',
    '0006_012',
    '0006_013',
    '0006_014',
    '0006_015',
    '0006_016',
    '0006_017',
    '0007_001',
    '0007_002',
    '0007_003',
    '0007_004',
    '0007_005',
    '0007_006',
    '0007_007',
    '0007_009',
    '0007_010',
    '0007_011',
    '0007_012',
    '0007_016',
    '0007_018',
    '0007_019',
    '0007_020',
    '0007_021',
    '0007_022',
    '0007_023',
    '0007_024',
    '0007_025',
    '0007_026',
    '0007_027',
    '0007_028',
    '0007_029',
    '0007_030',
    '0007_031',
    '0007_033',
    '0007_034',
    '0007_035',
    '0007_036',
    '0007_037',
    '0007_038',
    '0007_039',
    '0007_040',
    '0007_041',
    '0007_042',
    '0007_043',
    '0007_044',
    '0007_046',
    '0007_048',
    '0007_049',
    '0007_050',
    '0007_051',
    '0007_052',
    '0007_053',
    '0007_055',
    '0007_056',
    '0007_058',
    '0007_059',
    '0007_060',
    '0007_063',
    '0007_064',
    '0007_065',
    '0007_066',
    '0007_067',
    '0007_068',
    '0007_073',
    '0007_074',
    '0007_075',
    '0007_076',
    '0007_077',
    '0007_079',
    '0007_080',
    '0008_006',
    '0008_007',
    '0008_011',
    '0008_017',
    '0009_001',
    '0009_002',
    '0009_004',
    '0009_005',
    '0009_006',
    '0009_007',
    '0009_008',
    '0009_010',
    '0009_011',
    '0009_013',
    '0009_014',
    '0009_015',
    '0009_017',
    '0009_018',
    '0009_020',
    '0009_021',
    '0009_022',
    '0009_024',
    '0009_025',
    '0009_027',
    '0009_029',
    '0009_033',
    '0009_034',
    '0009_035',
    '0009_038',
    '0009_039',
    '0009_040',
    '0009_041',
    '0009_042',
    '0009_043',
    '0009_045',
    '0009_046',
    '0009_047',
    '0009_048',
    '0009_049',
    '0009_050',
    '0009_051',
    '0009_054',
    '0009_056',
    '0009_057',
    '0009_058',
    '0009_059',
    '0009_061',
    '0011_001',
    '0011_002',
    '0011_003',
    '0011_004',
    '0011_006',
    '0011_007',
    '0011_009',
    '0011_010',
    '0012_003',
    '0012_005',
    '0012_010',
    '0012_011',
    '0012_013',
    '0012_014',
    '0012_015',
    '0012_016',
    '0012_017',
    '0012_018',
    '0012_020',
    '0012_022',
    '0012_023',
    '0012_024',
    '0013_002',
    '0013_003',
    '0013_004',
    '0013_005',
    '0013_006',
    '0013_007',
    '0013_008',
    '0013_010',
    '0013_011',
    '0013_012',
    '0013_013',
    '0013_014',
    '0013_015',
    '0013_016',
    '0013_017',
    '0013_018',
    '0013_019',
    '0013_020',
    '0013_021',
    '0013_022',
    '0013_023',
    '0013_024',
    '0013_025',
    '0013_027',
    '0013_028',
    '0013_029',
    '0013_030',
    '0013_032',
    '0013_033',
    '0013_038',
    '0013_039',
    '0013_040',
    '0013_041',
    '0013_042',
    '0013_043',
    '0013_044',
    '0013_045',
    '0013_046',
    '0013_047',
    '0013_049',
    '0013_050',
    '0013_051',
    '0013_052',
    '0013_053',
    '0013_055',
    '0013_056',
    '0013_059',
    '0013_060',
    '0013_062',
    '0013_063',
    '0014_001',
    '0014_002',
    '0014_003',
    '0014_004',
    '0014_005',
    '0014_006',
    '0014_007',
    '0014_008',
    '0014_009',
    '0014_010',
    '0014_011',
    '0014_012',
    '0014_013',
    '0014_014',
    '0014_015',
    '0014_016',
    '0014_018',
    '0014_019',
    '0014_020',
    '0014_021',
    '0014_022',
    '0014_023',
    '0014_024',
    '0014_025',
    '0014_026',
    '0014_027',
    '0015_000',
    '0015_001',
    '0015_002',
    '0015_003',
    '0015_004',
    '0015_005',
    '0015_006',
    '0015_007',
    '0015_008',
    '0015_009',
    '0015_010',
    '0015_011',
    '0015_012',
    '0016_001',
    '0016_004',
    '0016_005',
    '0016_006',
    '0016_007',
    '0016_008',
    '0016_009',
    '0016_011',
    '0016_012',
    '0016_013',
    '0016_014',
    '0016_015',
    '0016_016',
    '0016_017',
    '0016_018',
    '0016_019',
    '0016_020',
    '0016_021',
    '0016_022',
    '0016_023',
    '0016_024',
    '0016_025',
    '0016_026',
    '0016_027',
    '0016_028',
    '0016_029',
    '0017_008',
    '0017_009',
    '0021_000',
    '0021_001',
    '0021_002',
    '0021_003',
    '0021_004',
    '0021_005',
    '0021_006',
    '0021_007',
    '0021_008',
    '0021_009',
    '0021_010',
    '0021_011',
    '0021_012',
    '0021_013',
    '0021_014',
    '0021_015',
    '0021_016',
    '0021_017',
    '0021_018',
    '0021_019',
    '0022_003',
    '0022_005',
    '0022_006',
    '0022_007',
    '0022_008',
    '0022_009',
    '0022_011',
    '0022_013',
    '0023_001',
    '0023_002',
    '0023_003',
    '0023_004',
    '0023_005',
    '0023_006',
    '0023_007',
    '0023_008',
    '0023_009',
    '0023_010',
    '0023_011',
    '0023_013',
    '0024_002',
    '0024_003',
    '0024_004',
    '0024_005',
    '0024_007',
    '0024_008',
    '0024_009',
    '0024_010',
    '0024_011',
    '0024_012',
    '0024_013',
    '0024_014',
    '0026_001',
    '0026_002',
    '0026_004',
    '0026_005',
    '0026_007',
    '0026_009',
    '0026_010',
    '0027_003',
    '0027_004',
    '0027_005',
    '0027_006',
    '0027_008',
    '0027_009',
    '0027_010',
    '0027_011',
    '0027_012',
    '0027_013',
    '0027_014',
    '0027_015',
    '0027_016',
    '0027_018',
    '0027_020',
    '0027_021',
    '0027_022',
    '0027_023',
    '0027_024',
    '0027_025',
    '0027_027',
    '0027_028',
    '0027_029',
    '0029_002',
    '0029_003',
    '0029_004',
    '0029_005',
    '0029_006',
    '0029_007',
    '0029_008',
    '0029_009',
    '0029_010',
    '0029_011',
    '0029_012',
    '0029_013',
    '0029_015',
    '0029_016',
    '0029_017',
    '0029_018',
    '0029_019',
    '0029_020',
    '0029_021',
    '0029_022',
    '0029_023',
    '0029_024',
    '0029_025',
    '0029_026',
    '0029_027',
    '0029_028',
    '0029_029',
    '0030_002',
    '0035_001',
    '0035_002',
    '0035_003',
    '0035_004',
    '0035_005',
    '0035_006',
    '0035_007',
    '0035_008',
    '0035_009',
    '0035_010',
    '0035_012',
    '0035_013',
    '0035_015',
    '0035_016',
    '0035_017',
    '0035_018',
    '0035_019',
    '0035_020',
    '0035_021',
    '0035_022',
    '0035_023',
    '0035_024',
    '0035_025',
    '0035_026',
    '0035_027',
    '0035_028',
    '0035_029',
    '0036_001',
    '0036_002',
    '0036_003',
    '0036_004',
    '0036_006',
    '0036_008',
    '0036_009',
    '0036_010',
    '0036_011',
    '0036_012',
    '0036_013',
    '0036_014',
    '0036_016',
    '0036_017',
    '0036_018',
    '0036_020',
    '0040_002',
    '0040_003',
    '0040_004',
    '0040_008',
    '0040_009',
    '0040_010',
    '0040_011',
    '0040_012',
    '0040_014',
    '0045_001',
    '0045_002',
    '0045_004',
    '0045_005',
    '0045_006',
    '0045_007',
    '0045_008',
    '0045_009',
    '0045_010',
    '0045_011',
    '0045_013',
    '0045_014',
    '0045_015',
    '0045_016',
    '0045_017',
    '0045_019',
    '0045_021',
    '0045_022',
    '0045_023',
    '0045_024',
    '0046_006',
    '0046_008',
    '0046_009',
    '0046_010',
    '0046_011',
    '0047_002',
    '0047_003',
    '0049_001',
    '0049_002',
    '0049_003',
    '0049_004',
    '0049_005',
    '0049_006',
    '0049_007',
    '0049_009',
    '0049_010',
    '0049_011',
    '0051_001',
    '0051_002',
    '0051_004',
    '0051_005',
    '0051_006',
    '0051_007',
    '0051_009',
    '0051_010',
    '0051_011',
    '0051_014',
    '0051_015',
    '0051_016',
    '0051_017',
    '0051_018',
    '0051_019',
    '0051_020',
    '0056_001',
    '0056_005',
    '0056_009',
    '0057_003',
    '0057_005',
    '0057_006',
    '0057_007',
    '0057_008',
    '0059_001',
    '0059_002',
    '0059_003',
    '0059_004',
    '0059_005',
    '0059_006',
    '0059_008',
    '0059_009',
    '0059_010',
    '0059_011',
    '0059_012',
    '0059_013',
    '0059_014',
    '0059_015',
    '0059_016',
    '0059_017',
    '0059_018',
    '0059_019',
    '0059_020',
    '0059_021',
    '0059_022',
    '0059_023',
    '0059_024',
    '0059_025',
    '0059_026',
    '0059_027',
    '0059_028',
    '0061_001',
    '0061_002',
    '0061_003',
    '0061_005',
    '0061_006',
    '0061_007',
    '0061_008',
    '0061_009',
    '0061_010',
    '0065_001',
    '0065_003',
    '0065_004',
    '0065_005',
    '0065_006',
    '0065_007',
    '0065_008',
    '0065_009',
    '0065_011',
    '0065_012',
    '0065_013',
    '0065_014',
    '0068_001',
    '0068_002',
    '0068_003',
    '0068_004',
    '0068_005',
    '0068_006',
    '0068_007',
    '0068_008',
    '0068_009',
    '0068_010',
    '0068_011',
    '0068_013',
    '0068_014',
    '0068_015',
    '0068_016',
    '0068_017',
    '0071_001',
    '0071_003',
    '0071_004',
    '0071_005',
    '0071_006',
    '0071_007',
    '0071_008',
    '0071_009',
    '0071_010',
    '0071_012',
    '0071_013',
    '0071_014',
    '0071_015',
    '0071_016',
    '0071_017',
    '0071_019',
    '0073_001',
    '0073_002',
    '0073_003',
    '0073_004',
    '0073_005',
    '0073_006',
    '0073_007',
    '0073_008',
    '0073_010',
    '0073_011',
    '0073_012',
    '0073_013',
    '0073_015',
    '0073_016',
    '0073_017',
    '0073_018',
    '0073_019',
    '0075_001',
    '0075_002',
    '0075_004',
    '0075_005',
    '0075_006',
    '0075_007',
    '0075_008',
    '0075_009',
    '0075_010',
    '0075_011',
    '0075_012',
    '0075_013',
    '0075_014',
    '0075_015',
    '0075_016',
    '0075_017',
    '0075_018',
    '0075_019',
    '0075_020',
    '0075_021',
    '0075_022',
    '0075_023',
    '0075_024',
    '0075_025',
    '0075_026',
    '0075_027',
    '0075_028',
    '0075_029',
    '0076_006',
    '0076_007',
    '0077_000',
    '0077_001',
    '0077_002',
    '0077_003',
    '0077_004',
    '0077_005',
    '0077_006',
    '0077_008',
    '0077_009',
    '0077_010',
    '0077_011',
    '0077_012',
    '0077_013',
    '0079_001',
    '0079_002',
    '0079_003',
    '0079_004',
    '0079_006',
    '0079_007',
    '0079_008',
    '0079_009',
    '0079_010',
    '0079_011',
    '0079_012',
    '0079_013',
    '0079_014',
    '0079_015',
    '0079_016',
    '0079_017',
    '0079_018',
    '0079_019',
    '0079_020',
    '0079_021',
    '0079_022',
    '0079_025',
    '0079_026',
    '0079_027',
    '0079_028',
    '0079_029',
    '0079_030',
    '0079_031',
    '0079_032',
    '0079_033',
    '0079_034',
    '0079_035',
    '0079_036',
    '0080_000',
    '0080_001',
    '0080_002',
    '0080_003',
    '0080_004',
    '0080_005',
    '0080_006',
    '0080_007',
    '0080_008',
    '0080_009',
    '0080_010',
    '0080_011',
    '0080_012',
    '0080_013',
    '0080_014',
    '0080_015',
    '0080_016',
    '0080_017',
    '0080_018',
    '0080_019',
    '0080_020',
    '0080_021',
    '0080_022',
    '0080_023',
    '0080_024',
    '0080_025',
    '0080_026',
    '0080_027',
    '0080_028',
    '0080_029',
    '0080_030',
    '0080_031',
    '0080_032',
    '0080_033',
    '0080_034',
    '0080_035',
    '0080_036',
    '0080_037',
    '0080_038',
    '0080_039',
    '0080_040',
    '0080_041',
    '0081_001',
    '0081_003',
    '0081_004',
    '0081_007',
    '0081_008',
    '0081_009',
    '0081_010',
    '0081_011',
    '0081_012',
    '0081_013',
    '0081_014',
    '0081_017',
    '0081_018',
    '0082_000',
    '0082_001',
    '0083_001',
    '0083_002',
    '0083_003',
    '0083_004',
    '0083_005',
    '0083_006',
    '0083_007',
    '0083_008',
    '0083_010',
    '0083_012',
    '0084_001',
    '0084_002',
    '0084_003',
    '0084_004',
    '0084_005',
    '0084_006',
    '0084_007',
    '0084_008',
    '0084_009',
    '0084_010',
    '0084_011',
    '0084_012',
    '0084_013',
    '0084_015',
    '0084_016',
    '0084_017',
    '0084_018',
    '0085_000',
    '0085_001',
    '0085_003',
    '0085_005',
    '0085_006',
    '0085_007',
    '0085_009',
    '0085_010',
    '0085_011',
    '0085_012',
    '0085_013',
    '0089_002',
    '0089_003',
    '0089_004',
    '0089_005',
    '0089_006',
    '0089_007',
    '0089_008',
    '0089_011',
    '0089_012',
    '0092_001',
    '0092_002',
    '0092_003',
    '0092_004',
    '0092_005',
    '0092_006',
    '0092_007',
    '0092_008',
    '0092_009',
    '0092_010',
    '0092_011',
    '0092_012',
    '0092_013',
    '0092_014',
    '0092_015',
    '0092_016',
    '0092_017',
    '0092_018',
    '0092_019',
    '0092_020',
    '0092_021',
    '0092_022',
    '0092_023',
    '0092_024',
    '0092_025',
    '0092_026',
    '0092_027',
    '0092_028',
    '0092_029',
    '0092_030',
    '0092_031',
    '0092_032',
    '0092_033',
    '0092_034',
    '0092_035',
    '0093_001',
    '0093_002',
    '0093_004',
    '0093_006',
    '0093_007',
    '0093_008',
    '0093_009',
    '0093_010',
    '0093_011',
    '0093_012',
    '0093_013',
    '0093_014',
    '0093_015',
    '0093_016',
    '0093_017',
    '0093_018',
    '0093_019',
    '0093_020',
    '0093_021',
    '0093_022',
    '0093_023',
    '0093_024',
    '0094_001',
    '0094_003',
    '0094_004',
    '0094_005',
    '0094_006',
    '0094_008',
    '0094_009',
    '0094_010',
    '0094_011',
    '0094_012',
    '0096_005',
    '0096_006',
    '0096_007',
    '0096_008',
    '0096_009',
    '0096_010',
    '0096_012',
    '0096_013',
    '0096_014',
    '0096_015',
    '0096_018',
    '0096_019',
    '0096_027',
    '0096_028',
    '0096_029',
    '0109_000',
    '0109_001',
    '0110_001',
    '0110_003',
    '0110_005',
    '0110_006',
    '0110_008',
    '0110_009',
    '0110_011',
    '0110_012',
    '0110_013',
    '0110_014',
    '0110_017',
    '0110_018',
    '0111_002',
    '0111_003',
    '0111_006',
    '0111_007',
    '0111_008',
    '0111_009',
    '0114_003',
    '0117_002',
    '0117_003',
    '0117_004',
    '0117_005',
    '0117_006',
    '0117_007',
    '0117_008',
    '0117_009',
    '0117_010',
    '0117_011',
    '0117_012',
    '0117_013',
    '0117_014',
    '0117_015',
    '0117_016',
    '0117_017',
    '0117_018',
    '0117_019',
    '0117_020',
    '0117_021',
    '0117_022',
    '0121_001',
    '0121_005',
    '0121_007',
    '0121_008',
    '0121_010',
    '0121_011',
    '0123_001',
    '0123_002',
    '0123_003',
    '0123_004',
    '0123_005',
    '0123_007',
    '0123_009',
    '0123_010',
    '0123_011',
    '0123_012',
    '0123_014',
    '0123_015',
    '0123_016',
    '0123_017',
    '0123_018',
    '0123_019',
    '0124_001',
    '0124_002',
    '0124_003',
    '0124_004',
    '0124_005',
    '0124_006',
    '0124_007',
    '0124_008',
    '0124_009',
    '0124_010',
    '0124_011',
    '0124_012',
    '0124_013',
    '0124_014',
    '0124_015',
    '0124_016',
    '0124_017',
    '0124_018',
    '0124_019',
    '0124_020',
    '0124_021',
    '0124_022',
    '0124_023',
    '0126_000',
    '0126_002',
    '0126_003',
    '0126_004',
    '0126_005',
    '0126_009',
    '0126_011',
    '0128_002',
    '0128_005',
    '0128_007',
    '0128_008',
    '0128_009',
    '0129_000',
    '0129_001',
    '0129_002',
    '0129_003',
    '0129_005',
    '0129_006',
    '0129_007',
    '0129_008',
    '0129_009',
    '0129_010',
    '0129_011',
    '0129_012',
    '0129_013',
    '0129_014',
    '0129_015',
    '0129_016',
    '0129_017',
    '0129_018',
    '0129_019',
    '0131_001',
    '0131_002',
    '0131_014',
    '0134_004',
    '0134_008',
    '0140_001',
    '0140_003',
    '0140_006',
    '0140_007',
    '0140_010',
    '0140_011',
    '0140_013',
    '0140_014',
    '0142_008',
    '0144_001',
    '0144_002',
    '0144_003',
    '0144_004',
    '0144_005',
    '0144_006',
    '0144_007',
    '0144_008',
    '0144_009',
    '0144_010',
    '0144_011',
    '0144_012',
    '0144_013',
    '0144_014',
    '0144_015',
    '0144_016',
    '0144_017',
    '0144_018',
    '0145_001',
    '0145_002',
    '0145_003',
    '0145_004',
    '0145_005',
    '0145_006',
    '0145_007',
    '0145_008',
    '0145_010',
    '0145_011',
    '0145_012',
    '0145_013',
    '0145_014',
    '0145_015',
    '0146_004',
    '0146_005',
    '0146_006',
    '0146_007',
    '0146_008',
    '0146_009',
    '0146_010',
    '0146_011',
    '0146_012',
    '0146_013',
    '0146_014',
    '0146_015',
    '0146_016',
    '0146_017',
    '0146_019',
    '0146_020',
    '0146_021',
    '0146_022',
    '0146_023',
    '0146_024',
    '0146_025',
    '0146_026',
    '0147_001',
    '0147_002',
    '0147_003',
    '0147_004',
    '0147_005',
    '0147_008',
    '0147_009',
    '0148_001',
    '0148_002',
    '0148_004',
    '0148_005',
    '0148_006',
    '0148_008',
    '0148_009',
    '0148_010',
    '0148_011',
    '0148_012',
    '0148_014',
    '0150_001',
    '0150_002',
    '0150_003',
    '0150_004',
    '0150_005',
    '0150_006',
    '0150_007',
    '0150_008',
    '0150_009',
    '0150_010',
    '0150_011',
    '0150_012',
    '0150_014',
    '0150_015',
    '0150_017',
    '0150_018',
    '0150_019',
    '0156_000',
    '0156_001',
    '0156_003',
    '0156_004',
    '0156_005',
    '0156_006',
    '0156_007',
    '0156_008',
    '0156_009',
    '0156_010',
    '0156_012',
    '0156_013',
    '0156_014',
    '0156_015',
    '0156_016',
    '0156_017',
    '0156_018',
    '0156_019',
    '0156_020',
    '0156_021',
    '0156_022',
    '0156_023',
    '0156_024',
    '0156_025',
    '0156_026',
    '0156_027',
    '0156_028',
    '0156_029',
    '0156_030',
    '0156_031',
    '0156_032',
    '0158_002',
    '0158_004',
    '0159_004',
    '0159_005',
    '0159_007',
    '0159_008',
    '0159_009',
    '0159_010',
    '0159_011',
    '0159_012',
    '0162_005',
    '0162_008',
    '0164_002',
    '0164_003',
    '0164_005',
    '0164_007',
    '0164_008',
    '0164_009',
    '0164_010',
    '0164_011',
    '0164_012',
    '0164_013',
    '0164_015',
    '0164_016',
    '0164_017',
    '0164_018',
    '0164_019',
    '0164_020',
    '0165_003',
    '0165_007',
    '0168_001',
    '0168_002',
    '0168_003',
    '0168_004',
    '0168_005',
    '0168_006',
    '0168_007',
    '0168_008',
    '0168_009',
    '0168_011',
    '0168_012',
    '0168_014',
    '0168_015',
    '0169_001',
    '0169_003',
    '0169_005',
    '0169_006',
    '0169_008',
    '0169_010',
    '0169_011',
    '0170_002',
    '0170_004',
    '0170_013',
    '0171_000',
    '0171_001',
    '0171_002',
    '0171_003',
    '0171_004',
    '0171_005',
    '0171_006',
    '0171_007',
    '0171_008',
    '0171_009',
    '0171_010',
    '0171_011',
    '0171_012',
    '0171_013',
    '0171_014',
    '0171_015',
    '0171_016',
    '0171_018',
    '0171_019',
    '0171_020',
    '0171_021',
    '0171_022',
    '0171_023',
    '0171_024',
    '0171_025',
    '0171_026',
    '0171_027',
    '0171_028',
    '0171_029',
    '0174_000',
    '0174_001',
    '0174_003',
    '0174_005',
    '0174_006',
    '0174_007',
    '0177_001',
    '0177_002',
    '0177_003',
    '0177_004',
    '0177_005',
    '0177_006',
    '0177_007',
    '0177_009',
    '0177_010',
    '0177_011',
    '0177_012',
    '0177_013',
    '0177_014',
    '0177_015',
    '0177_016',
    '0177_017',
    '0177_018',
    '0177_019',
    '0177_020',
    '0177_021',
    '0177_022',
    '0178_001',
    '0178_002',
    '0178_004',
    '0178_005',
    '0178_006',
    '0178_007',
    '0178_009',
    '0183_000',
    '0183_002',
    '0183_003',
    '0183_007',
    '0184_000',
    '0184_001',
    '0184_002',
    '0184_003',
    '0184_004',
    '0184_005',
    '0184_006',
    '0184_008',
    '0184_010',
    '0184_011',
    '0184_012',
    '0184_013',
    '0184_016',
    '0184_017',
    '0186_001',
    '0186_002',
    '0186_003',
    '0186_004',
    '0186_005',
    '0186_006',
    '0186_007',
    '0186_008',
    '0186_009',
    '0186_010',
    '0186_011',
    '0186_012',
    '0186_013',
    '0186_014',
    '0186_015',
    '0186_016',
    '0186_017',
    '0186_018',
    '0186_019',
    '0186_020',
    '0186_021',
    '0186_022',
    '0186_023',
    '0186_024',
    '0187_004',
    '0187_006',
    '0187_007',
    '0187_008',
    '0187_009',
    '0187_011',
    '0187_013',
    '0187_014',
    '0187_015',
    '0188_001',
    '0188_002',
    '0188_003',
    '0188_004',
    '0188_005',
    '0188_006',
    '0188_007',
    '0188_008',
    '0188_009',
    '0188_010',
    '0188_011',
    '0188_012',
    '0188_013',
    '0188_014',
    '0188_015',
    '0188_016',
    '0188_018',
    '0188_019',
    '0188_020',
    '0188_021',
    '0188_022',
    '0188_023',
    '0188_025',
    '0188_026',
    '0189_000',
    '0189_002',
    '0189_003',
    '0189_004',
    '0189_005',
    '0189_008',
    '0191_002',
    '0191_003',
    '0191_004',
    '0191_005',
    '0191_006',
    '0191_007',
    '0191_009',
    '0191_010',
    '0191_011',
    '0191_012',
    '0191_013',
    '0191_014',
    '0191_015',
    '0191_016',
    '0191_017',
    '0191_018',
    '0191_019',
    '0193_000',
    '0193_003',
    '0193_004',
    '0193_005',
    '0193_006',
    '0193_008',
    '0193_009',
    '0193_010',
    '0193_011',
    '0193_012',
    '0193_013',
    '0193_014',
    '0193_015',
    '0193_016',
    '0193_017',
    '0193_018',
    '0199_000',
    '0199_001',
    '0199_002',
    '0199_003',
    '0199_004',
    '0199_005',
    '0199_006',
    '0199_007',
    '0199_008',
    '0199_009',
    '1000_001',
    '1000_002',
    '1000_003',
    '1000_004',
    '1000_007',
    '1000_008',
    '1000_009',
    '1000_011',
    '1000_012',
    '1000_013',
    '1000_014',
    '1000_016',
    '1000_018',
    '1000_020',
    '1000_021',
    '1000_022',
    '1001_001',
    '1001_002',
    '1001_003',
    '1001_004',
    '1001_005',
    '1001_006',
    '1001_007',
    '1001_009',
    '1001_010',
    '1001_011',
    '1001_013',
    '1001_014',
    '1001_015',
    '1001_016',
    '1001_017',
    '1001_018',
    '1001_019',
    '1001_021',
    '1001_022',
    '1001_023',
    '1001_024',
    '1004_001',
    '1004_002',
    '1004_003',
    '1004_004',
    '1004_005',
    '1004_006',
    '1004_007',
    '1004_009',
    '1004_010',
    '1004_011',
    '1004_013',
    '1004_014',
    '1004_015',
    '1004_016',
    '1004_017',
    '1005_001',
    '1005_003',
    '1005_004',
    '1005_005',
    '1005_006',
    '1005_007',
    '1005_009',
    '1005_010',
    '1005_011',
    '1005_012',
    '1005_014',
    '1005_015',
    '1005_018',
    '1005_019',
    '1005_020',
    '1005_021',
    '1006_000',
    '1006_001',
    '1006_003',
    '1006_005',
    '1006_006',
    '1006_007',
    '1006_010',
    '1006_011',
    '1006_012',
    '1006_013',
    '1006_014',
    '1006_015',
    '1006_016',
    '1007_001',
    '1007_002',
    '1007_006',
    '1007_008',
    '1007_013',
    '1007_014',
    '1007_016',
    '1007_017',
    '1007_018',
    '1007_019',
    '1008_000',
    '1008_001',
    '1008_002',
    '1008_004',
    '1008_006',
    '1008_007',
    '1008_008',
    '1008_009',
    '1008_010',
    '1008_011',
    '1008_013',
    '1008_014',
    '1008_015',
    '1008_016',
    '1008_018',
    '1011_000',
    '1011_001',
    '1011_003',
    '1011_005',
    '1011_006',
    '1011_008',
    '1011_009',
    '1011_010',
    '1014_001',
    '1014_002',
    '1014_003',
    '1014_004',
    '1014_005',
    '1014_006',
    '1014_007',
    '1014_009',
    '1017_004',
    '1017_007',
    '1017_008',
    '1017_010',
    '1017_014',
    '1018_001',
    '1018_008',
    '1019_003',
    '1019_006',
    '1019_007',
    '1019_008',
    '1019_010',
    '1020_001',
    '1020_002',
    '1020_003',
    '1020_005',
    '1020_006',
    '1020_007',
    '1020_008',
    '1020_009',
    '1020_010',
    '1020_011',
    '1020_012',
    '1021_002',
    '1021_003',
    '1021_005',
    '1022_001',
    '1022_002',
    '1022_003',
    '1022_005',
    '1022_007',
    '1022_008',
    '1022_009',
    '1022_010',
    '1022_011',
    '1022_012',
    '1022_013',
    '1022_014',
    '1022_015',
    '1022_016',
    '1022_017',
    '1023_001',
    '1023_002',
    '1023_003',
    '1023_004',
    '1023_005',
    '1023_006',
    '1023_007',
    '1023_008',
    '1023_011',
    '1023_012',
    '1023_013',
    '1023_014',
    '1026_001',
    '1026_004',
    '1026_005',
    '1026_006',
    '1026_007',
    '1026_009',
    '1026_010',
    '1026_011',
    '1026_012',
    '1026_014',
    '1026_015',
    '1026_016',
    '1026_017',
    '1026_018',
    '1026_019',
    '1026_020',
    '1030_004',
    '1030_005',
    '1030_009',
    '1033_000',
    '1033_001',
    '1033_002',
    '1033_003',
    '1033_004',
    '1033_005',
    '1033_006',
    '1033_007',
    '1033_008',
    '1033_009',
    '1033_010',
    '1033_011',
    '1033_012',
    '1033_013',
    '1033_014',
    '1033_015',
    '1033_016',
    '1033_018',
    '1033_019',
    '1033_020',
    '1033_021',
    '1033_022',
    '1034_001',
    '1034_003',
    '1034_004',
    '1034_005',
    '1036_000',
    '1036_001',
    '1036_002',
    '1036_003',
    '1036_004',
    '1036_005',
    '1036_006',
    '1036_008',
    '1036_009',
    '1036_011',
    '1036_012',
    '1036_013',
    '1036_014',
    '1036_015',
    '1036_016',
    '1037_000',
    '1037_001',
    '1037_002',
    '1037_003',
    '1037_004',
    '1037_005',
    '1037_006',
    '1037_007',
    '1037_008',
    '1037_009',
    '1037_010',
    '1037_011',
    '1037_012',
    '1037_013',
    '1037_014',
    '1037_015',
    '1037_016',
    '1037_017',
    '1037_018',
    '1037_021',
    '1038_008',
    '1041_001',
    '1041_002',
    '1041_003',
    '1041_004',
    '1041_005',
    '1041_006',
    '1041_007',
    '1041_008',
    '1041_009',
    '1041_011',
    '1044_004',
    '1044_005',
    '1044_006',
    '1044_007',
    '1044_008',
    '1044_009',
    '1044_010',
    '1044_011',
    '1047_004',
    '1048_002',
    '1048_004',
    '1048_005',
    '1048_006',
    '1048_007',
    '1048_008',
    '1048_010',
    '1048_011',
    '1048_012',
    '1048_013',
    '1065_001',
    '1065_005',
    '1065_006',
    '1065_009',
    '1066_001',
    '1066_002',
    '1066_005',
    '1066_006',
    '1066_007',
    '1066_008',
    '1066_010',
    '1067_002',
    '1067_005',
    '1067_006',
    '1067_007',
    '1067_008',
    '1067_009',
    '1067_012',
    '1067_013',
    '1067_014',
    '1067_015',
    '1067_017',
    '1072_001',
    '1072_002',
    '1072_003',
    '1072_004',
    '1072_006',
    '1072_007',
    '1072_008',
    '1072_009',
    '1072_010',
    '1073_001',
    '1073_003',
    '1073_004',
    '1073_007',
    '1073_008',
    '1073_009',
    '1073_011',
    '1073_012',
    '1073_013',
    '1073_014',
    '1073_015',
    '1076_002',
    '1081_002',
    '1081_003',
    '1081_004',
    '1081_006',
    '1081_007',
    '1081_008',
    '1081_009',
    '1081_010',
    '1081_011',
    '1084_000',
    '1084_002',
    '1084_007',
    '1084_010',
    '1084_012',
    '1095_001',
    '1095_002',
    '1095_004',
    '1095_005',
    '1095_009',
    '1095_012',
    '1095_013',
    '1095_014',
    '1095_016',
    '1095_017',
    '1095_018',
    '1095_019',
    '1099_009',
    '1103_000',
    '1103_001',
    '1103_002',
    '1103_003',
    '1103_004',
    '1103_005',
    '1103_006',
    '1103_007',
    '1103_008',
    '1103_009',
    '1103_011',
    '1103_014',
    '1103_015',
    '1103_016',
    '1103_018',
    '1103_019',
    '1103_020',
    '1103_021',
    '1103_022',
    '1105_002',
    '1105_006',
    '1105_008',
    '1105_010',
    '1114_007',
    '1114_009',
    '1120_000',
    '1120_001',
    '1120_002',
    '1120_003',
    '1120_005',
    '1120_006',
    '1120_007',
    '1120_008',
    '1120_009',
    '1120_010',
    '1120_011',
    '1120_012',
    '1120_013',
    '1120_014',
    '1120_015',
    '1120_016',
    '1120_017',
    '1120_018',
    '1120_019',
    '1120_020',
    '1120_021',
    '1120_022',
    '1120_023',
    '1120_024',
    '1120_026',
    '1120_027',
    '1120_028',
    '1120_029',
    '1122_001',
    '1122_004',
    '1122_006',
    '1122_008',
    '1122_010',
    '1126_001',
    '1126_002',
    '1126_004',
    '1126_005',
    '1126_006',
    '1126_007',
    '1126_010',
    '1126_011',
    '1126_013',
    '1126_014',
    '1131_002',
    '1131_003',
    '1131_004',
    '1131_005',
    '1131_006',
    '1131_008',
    '1131_010',
    '1131_011',
    '1131_013',
    '1139_002',
    '1139_006',
    '1139_007',
    '1139_009',
    '1139_010',
    '1139_011',
    '1139_012',
    '1139_014',
    '1139_015',
    '1139_016',
    '1139_019',
    '1139_020',
    '1139_021',
    '1139_022',
    '1139_023',
    '1139_024',
    '1139_026',
    '1141_000',
    '1141_001',
    '1141_002',
    '1141_004',
    '1141_005',
    '1141_006',
    '1141_007',
    '1141_008',
    '1141_009',
    '1144_004',
    '1144_008',
    '1145_001',
    '1145_002',
    '1145_003',
    '1145_004',
    '1145_005',
    '1145_006',
    '1145_007',
    '1145_008',
    '1145_009',
    '1145_010',
    '1145_011',
    '1145_012',
    '1145_013',
    '1145_014',
    '1145_015',
    '1145_016',
    '1145_017',
    '1145_018',
    '1145_019',
    '1145_020',
    '1145_021',
    '1145_022',
    '1145_023',
    '1145_024',
    '1145_025',
    '1145_026',
    '1145_027',
    '1146_001',
    '1146_002',
    '1146_003',
    '1146_004',
    '1146_005',
    '1146_007',
    '1146_008',
    '1146_009',
    '1146_010',
    '1149_000',
    '1149_001',
    '1149_006',
    '1149_007',
    '1149_008',
    '1149_009',
    '1149_010',
    '1151_001',
    '1151_002',
    '1151_003',
    '1151_004',
    '1151_005',
    '1151_006',
    '1151_007',
    '1151_008',
    '1151_009',
    '1151_011',
    '1151_013',
    '1151_014',
    '1151_015',
  ],
}
export const mockSmallSadDataset = {
  id: 1,
  name: '悲伤小数据集',
  sortKey: '悲伤情绪唤起强度（悲伤小数据集）',
  segments: [
    '0001_000',
    '0001_001',
    '0001_003',
    '0001_004',
    '0001_005',
    '0001_006',
    '0001_007',
    '0001_010',
    '0001_011',
    '0001_012',
    '0001_013',
    '0001_014',
    '0001_015',
    '0006_001',
    '0006_002',
    '0006_003',
    '0006_005',
    '0006_006',
    '0006_009',
    '0006_010',
    '0006_011',
    '0006_012',
    '0006_013',
    '0006_014',
    '0006_015',
    '0006_016',
    '0006_017',
    '0007_001',
    '0007_002',
    '0007_003',
    '0007_004',
    '0007_005',
    '0007_006',
    '0007_007',
    '0007_009',
    '0007_010',
  ],
}
export const mockDatasets = [mockSadDataset, mockSmallSadDataset]

const quickSort = (segments, historyCompares) => {
  if (segments.length < 2) {
    return null
  }
  const sentinel = segments[0]
  const smallOnes = []
  const bigOnes = []
  for (const segment of segments) {
    if (segment === sentinel) {
      continue
    }
    const item = find(historyCompares, (item) => {
      if (item.videoOSSID1 === sentinel && item.videoOSSID2 === segment) {
        return true
      }
      return false
    })
    if (isNil(item)) {
      return [sentinel, segment]
    }
    if (item.compare === '>') {
      bigOnes.push(segment)
    } else if (item.compare === '<') {
      smallOnes.push(segment)
    }
  }
  const leftRes = quickSort(smallOnes, historyCompares)
  if (!isNil(leftRes)) {
    return leftRes
  }
  const rightRes = quickSort(bigOnes, historyCompares)
  if (!isNil(rightRes)) {
    return rightRes
  }
  return null
}

const historyComparesAllDatasets = new Map(
  mockDatasets.map(({ id }) => [id, []])
) // TODO: no global var

export const mockMakeSubmitAndRequestForNextQuestion = () => {
  // const historyComparesAllDatasets = new Map(mockDatasets.map(({id}) => [id, []]))
  return async (props) => {
    const { token, datasetID, videoOSSID1, videoOSSID2, compare } = props
    let historyCompares = historyComparesAllDatasets.get(datasetID)
    console.log('mockMakeSubmitAndRequestForNextQuestion', historyCompares)
    historyComparesAllDatasets.set(datasetID, [
      ...historyCompares,
      {
        videoOSSID1,
        videoOSSID2,
        compare,
      },
    ])
    historyCompares = historyComparesAllDatasets.get(datasetID)
    const token2userID = (token) => 1
    token2userID(token)
    const dataset = find(mockDatasets, (item) => item.id === datasetID)
    const res = quickSort(dataset.segments, historyCompares)
    const finished = isNil(res)
    if (!finished) {
      return { code: 1000, data: { finished, segments: res } }
    } else {
      return { code: 1000, data: { finished } }
    }
  }
}

export const mockMakeInitialRequestForNextQuestion = () => {
  const historyComparesAllDatasets = new Map(
    mockDatasets.map(({ id }) => [id, []])
  )
  return async (props) => {
    const { token, datasetID } = props
    const historyCompares = historyComparesAllDatasets.get(datasetID)
    const token2userID = (token) => 1
    token2userID(token)
    const dataset = find(mockDatasets, (item) => item.id === datasetID)
    const res = quickSort(dataset.segments, historyCompares)
    const finished = isNil(res)
    if (!finished) {
      return {
        code: 1000,
        data: { finished, segments: res, sortKey: dataset.sortKey },
      }
    } else {
      return { code: 1000, data: { finished, sortKey: dataset.sortKey } }
    }
  }
}
